import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../../utils/store";
import {getCurrentUser} from "aws-amplify/auth";
import {Cart, deletePostcardFromCart, getImage, isPromoCodeValid as _isPromoCodeValid} from "../../api/api";
import {
    cloneImage,
    getAlert,
    getPromotionFromPromoCode,
    isInInitialState,
    Location,
    sendToCheckout
} from "../app/common/postcardAppUtils";
import {Image, Postcard} from "../../utils/postcard";
import {AlertMessage} from "../app/common/imagesselection/alertsContext";
import {clearState, updateImage, updateLayout, updateLocation} from "../app/postcard-app/utils/postcardSlice";
import {v4 as uuid} from "uuid";

export interface CartState {
    cart: Cart | undefined
    promotion: number
    promoCodeName: string
    promoCodeId: string | undefined
    isLoadingCheckout: boolean
    isLoadingPromoCodeValidation: boolean
    isLoadingAddAnotherPostcard: boolean
    alerts: AlertMessage[]
    overwriteDialogOpen: boolean
}

const initialState: CartState = {
    cart: undefined,
    promotion: 1.0,
    promoCodeName: '',
    promoCodeId: undefined,
    isLoadingCheckout: false,
    isLoadingPromoCodeValidation: false,
    isLoadingAddAnotherPostcard: false,
    alerts: [],
    overwriteDialogOpen: false
}

const _addAnotherPostcard = async (dispatch: AppDispatch, getState: () => RootState) => {
    // Clear app state
    dispatch(clearState())

    // Initialize with last postcard in cart
    const { cart } = getState()
    if (cart.cart?.postcards.length !== 0) {
        const lastPostcard = cart.cart?.postcards[0].postcard

        if (lastPostcard) {
            const layout = lastPostcard.layout
            const images = lastPostcard.images
            const location: Location = {
                location: lastPostcard.location,
                latitude: lastPostcard.latitude,
                longitude: lastPostcard.longitude
            }

            dispatch(updateLayout(layout))

            let reader: FileReader
            const promises = []
            for (const image of images)
                // Need to get new signed URL from S3
                promises.push(getImage(image.id))

            const results = await Promise.all(promises)

            for (const dbImage of results) {
                if (dbImage) {
                    try {
                        const result = await fetch(dbImage.path)
                        const blob = await result.blob()

                        reader = new FileReader()
                        const id = uuid()

                        reader.onloadend = function () {
                            const path = reader.result as string;
                            const imageCopy: Image = {id: id, index: dbImage.index, path: path}
                            console.log("Updating state")
                            dispatch(updateImage(imageCopy))
                        };

                        const imageCopyFile: File = new File([blob], id, {type: 'image/jpeg'})
                        reader.readAsDataURL(imageCopyFile)

                    } catch (error) {
                        console.log(dbImage.path)
                        console.warn(error)
                    }
                }
            }

            dispatch(updateLocation(location))
        }
    }
}

const validatePromoCode = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('cart/validatePromoCode', async (data, { getState }) => {
    const { cart } = getState()
    const promoCodeName = cart.promoCodeName

    if (promoCodeName === '') {
        throw Error('Please insert a valid promo code')
    } else {
        const {userId} = await getCurrentUser()
        const result = await _isPromoCodeValid(promoCodeName, userId)

        if (result === null) throw Error('Unable to validate promo code')
        else if (result === false) throw Error('The promo code is invalid')

        return result.id
    }
})

const submitOrder = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('cart/submitOrder', async (data: { postcard: Postcard, price: number }[], { getState }) => {
    const { cart } = getState()
    const promoCodeId = cart.promoCodeId

    const response = await sendToCheckout(data.map(item => ({
        id: item.postcard.id, price: item.price
    })), window, promoCodeId)

    if (!response) {
        throw Error('Failed to process submission')
    }
})

const deletePostcard =  createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('cart/deletePostcard', async (data: { id: string, cartItemId: string }): Promise<{ id: string, cartItemId: string }> => {
    const postcardIdToDelete = data.id
    const cartItemIdToDelete = data.cartItemId

    const response = await deletePostcardFromCart(postcardIdToDelete, cartItemIdToDelete)

    if (!response) {
        throw Error('Unable to delete postcard from cart')
    }

    return data
})

const safeAddAnotherPostcard = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('cart/safeAddAnotherPostcard', async (data: boolean=true, { getState, dispatch }): Promise<boolean> => {
    const { app } = getState()
    const enforce = data
    if (enforce || isInInitialState(app)) {
        await _addAnotherPostcard(dispatch, getState)
        return true
    } else {
        dispatch(openOverwriteDialog())
        return false
    }
})

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updatePromoCodeName: (state, action: PayloadAction<string>) => {
            state.promoCodeName = action.payload
        },
        addErrorAlert: (state, action: PayloadAction<string>) => {
            state.alerts.push(getAlert(action.payload, 'error'))
        },
        removeAlert: (state, action: PayloadAction<string>) => {
            const id = action.payload
            state.alerts = [...state.alerts.filter(alert => alert.id !== id)]
        },
        setCart: (state, action: PayloadAction<Cart>) => {
            state.cart = action.payload
        },
        openOverwriteDialog: (state) => {
            state.overwriteDialogOpen = true
        },
        closeOverwriteDialog: (state) => {
            state.overwriteDialogOpen = false
        }
    },
    extraReducers:  builder => {
        builder
            .addCase(validatePromoCode.pending, (state) => {
                state.isLoadingPromoCodeValidation = true
            })
            .addCase(validatePromoCode.fulfilled, (state, action: PayloadAction<string>) => {
                state.isLoadingPromoCodeValidation = false
                state.promotion = getPromotionFromPromoCode(true, state.promoCodeName)
                state.promoCodeId = action.payload
                state.promoCodeName = ''
            })
            .addCase(validatePromoCode.rejected, (state, action) => {
                state.isLoadingPromoCodeValidation = false
                state.promotion = 1.0
                state.promoCodeId = undefined
                state.promoCodeName = ''
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(submitOrder.pending, (state) => {
                state.isLoadingCheckout = true
            })
            .addCase(submitOrder.fulfilled, (state) => {
                state.cart = initialState.cart
                state.promotion = initialState.promotion
                state.promoCodeId = initialState.promoCodeId
                state.promoCodeName = initialState.promoCodeName
                state.isLoadingCheckout = false
            })
            .addCase(submitOrder.rejected, (state, action) => {
                state.isLoadingCheckout = false
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(deletePostcard.pending, (state) => {

            })
            .addCase(deletePostcard.fulfilled, (state, action: PayloadAction<{ id: string, cartItemId: string }>) => {
                if (state.cart) {
                    state.cart.postcards = state.cart.postcards.filter(
                        postcardData => postcardData.cartItemId !== action.payload.cartItemId
                    )
                    state.alerts.push(getAlert('Successfully removed postcard', 'info'))
                }
            })
            .addCase(deletePostcard.rejected, (state, action) => {
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(safeAddAnotherPostcard.pending, (state, action) => {
                state.isLoadingAddAnotherPostcard = true
            })
            .addCase(safeAddAnotherPostcard.fulfilled, (state, action) => {
                state.isLoadingAddAnotherPostcard = false
            })
            .addCase(safeAddAnotherPostcard.rejected, (state, action) => {
                // Silently fails and redirects to /app with the wrong state (no big deal)
                state.isLoadingAddAnotherPostcard = false
            })
    }
})

export const {
    updatePromoCodeName,
    removeAlert,
    addErrorAlert,
    setCart,
    openOverwriteDialog,
    closeOverwriteDialog
} = cartSlice.actions

export { validatePromoCode, submitOrder, deletePostcard, safeAddAnotherPostcard }
export default cartSlice.reducer
export const selectCart = (state: RootState) => state.cart