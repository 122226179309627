import {Address, CHAIN_SETTING, Postcard, REPLY_BACK_SETTING} from "../../../../utils/postcard";
import {
    AppState,
    canGoToStep, getAlert,
    getEmptyPostcard, getInitialState,
    getMissingInformationForStep,
    getNextButtonDisabled,
    getNextStep,
    getPreviousStep,
    getReplyAddress,
    getReplyBack,
    preparePostcardForSubmission,
    processPostcardCreation,
    shouldUpdateUserAddress,
    STEPS
} from "../../common/postcardAppUtils";
import {v4 as uuid} from "uuid";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../../../../utils/store";
import {
    commonAddErrorAlert, commonAddInfoAlert,
    commonCloseErrorDialog, commonRemoveAlert, commonSetFontIfNotPresent,
    commonUpdateFont, commonUpdateImage, commonUpdateLayout,
    commonUpdateLocation,
    commonUpdateMessage
} from "../../common/utils/commonSlice";
import {PostcardAppInitialState, PostcardAppState, ReplyFreePostcardBody} from "./PostcardAppInitialState";
import {createCartItems, getUserCart, getUserData} from "../../../../api/api";
import {isAddressEmpty, stringToAddress} from "../../../../utils/utils";

const _processNextStepRequest = (state: AppState) => {
    if (getNextButtonDisabled(state.step, state.isLoadingSubmission, true, state.postcard)) {
        // Some information is missing, display error dialog
        state.errorDialog.open = true
        state.errorDialog.message = getMissingInformationForStep(state.step, state.postcard)
    } else {
        // Go to next step
        if (state.step === STEPS.PERSONAL_ADDRESS && shouldUpdateUserAddress(state.postcard.address, getReplyAddress(state.postcard))) {

        }

        state.step = getNextStep(state.step, state.initialState.initialState, getReplyBack(state.postcard))
    }
}

const addPostcardToCart = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('app/addPostcardToCart', async (data, { getState }) => {
    const { app, auth } = getState()

    if (auth.user) {
        // Get user cart
        const cartId = await getUserCart(auth.user.userId)
        if (!cartId) {
            throw new Error('Error while fetching user cart')
        } else {
            // Create postcard
            const submissionBundle = preparePostcardForSubmission(app.postcard, app.initialState)
            const creationResult = await processPostcardCreation(submissionBundle)

            if (!creationResult) {
                throw new Error('Failed to submit postcard')
            } else {
                const postcardId = creationResult.id
                const cartItemResult = await createCartItems(cartId, [postcardId])

                if (!cartItemResult) {
                    throw new Error('Failed to add postcard to cart')
                }
            }
        }
    }
})

const fetchUserAddress = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('app/fetchUserAddress', async (data, { getState }): Promise<Address | false> => {
    const { auth, app } = getState()

    if (auth.user && isAddressEmpty(app.postcard.settings.replyAddress)) {
        const result = await getUserData()

        if (result) {
            return stringToAddress(result.address)
        } else {
            throw Error("Unable to get user address")
        }
    }

    return false
})

const _clearState = (state: AppState) => {
    const initialState = getInitialState(uuid(), uuid(), uuid())
    state.isLoadingSubmission = false
    // Reset state for new postcard creation
    state.postcard = initialState.postcard
    state.initialState = initialState.initialState
    state.step = initialState.step
    state.isNextDisabled = initialState.isNextDisabled
}

const appSlice = createSlice({
    name: 'app',
    initialState: getInitialState(uuid(), uuid(), uuid()),
    reducers: {
        updateImage: commonUpdateImage<Postcard>,
        updateLayout: commonUpdateLayout<Postcard>,
        updateMessage: commonUpdateMessage<Postcard>,
        updateFont: commonUpdateFont<Postcard>,
        setFontIfNotPresent: commonSetFontIfNotPresent<Postcard>,
        updateLocation: commonUpdateLocation<Postcard>,
        updateAddress: (state, action: PayloadAction<Address>) => {
            state.postcard.address = action.payload
        },
        updateReplyBackSetting: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.postcard.settings.replyBackSetting = REPLY_BACK_SETTING.REPLY_PAID
                state.postcard.settings.chainSetting = CHAIN_SETTING.NONE
            } else {
                state.postcard.settings.replyBackSetting = REPLY_BACK_SETTING.NONE
                state.postcard.settings.chainSetting = CHAIN_SETTING.NONE
            }
        },
        updateChainSetting: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.postcard.settings.replyBackSetting = REPLY_BACK_SETTING.NONE
                state.postcard.settings.chainSetting = CHAIN_SETTING.CHAIN_PAID
            } else {
                state.postcard.settings.replyBackSetting = REPLY_BACK_SETTING.NONE
                state.postcard.settings.chainSetting = CHAIN_SETTING.NONE
            }
        },
        updateChainName: (state, action: PayloadAction<string>) => {
            state.postcard.settings.chain = {
                name: action.payload,
                id: uuid()
            }
        },
        updateReplyAddress: (state, action: PayloadAction<Address>) => {
            state.postcard.settings.replyAddress = action.payload
        },
        updateEnvelope: (state) => {
            state.postcard.settings.envelope = !state.postcard.settings.envelope
        },
        processInitialState: (state, action: PayloadAction<PostcardAppState>) => {
            state.initialState = action.payload
            switch (state.initialState.initialState) {
                case PostcardAppInitialState.REPLY_FREE:
                    state.postcard.settings.replyAddress = (action.payload.body as ReplyFreePostcardBody).addressToPrefill
                    break
                case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
                case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
                    state.postcard.settings.chainSetting = CHAIN_SETTING.CHAIN
                    break
            }
        },
        processGoToStepRequest: (state, action: PayloadAction<STEPS>) => {
            const currentStep = state.step
            const requestedStep = action.payload

            if (requestedStep < currentStep) {
                // Go to step
                state.step = requestedStep
            } else if (requestedStep === getNextStep(currentStep, state.initialState.initialState, getReplyBack(state.postcard))) {
                // Delegate to next step reducer
                _processNextStepRequest(state)
            } else if (requestedStep > currentStep) {  // Steps after next step
                // If there's still some missing information in the current step, show that as the error message
                if (getNextButtonDisabled(state.step, state.isLoadingSubmission, true, state.postcard)) {
                    state.errorDialog.open = true
                    state.errorDialog.message = getMissingInformationForStep(state.step, state.postcard)
                } else {
                    if (canGoToStep(
                        requestedStep,
                        state.initialState.initialState,
                        getReplyBack(state.postcard),
                        state.isLoadingSubmission,
                        true,
                        state.postcard
                    )) {
                        state.step = requestedStep
                    } else {
                        state.errorDialog.open = true
                        state.errorDialog.message = 'Please fill all the missing information before jumping to this step'
                    }
                }
            }
        },
        processNextStepRequest: _processNextStepRequest,
        processPreviousStepRequest: (state) => {
            if (state.step === STEPS.PERSONAL_ADDRESS && shouldUpdateUserAddress(state.postcard.address, getReplyAddress(state.postcard))) {

            }

            state.step = getPreviousStep(state.step, state.initialState.initialState, getReplyBack(state.postcard))
        },
        clearState: _clearState,
        closeErrorDialog: commonCloseErrorDialog<Postcard>,
        addErrorAlert: commonAddErrorAlert<Postcard>,
        addInfoAlert: commonAddInfoAlert<Postcard>,
        removeAlert: commonRemoveAlert<Postcard>
    },
    extraReducers: builder => {
        builder
            .addCase(addPostcardToCart.pending, (state) => {
                state.isLoadingSubmission = true
            })
            .addCase(addPostcardToCart.rejected, (state, action) => {
                state.isLoadingSubmission = false
                // TODO: TEST THIS and get type! - decide if we wanna use alert or dialog
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(addPostcardToCart.fulfilled, _clearState)
            .addCase(fetchUserAddress.rejected, (state, action) => {
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(fetchUserAddress.fulfilled, (state, action) => {
                if (action.payload)
                    state.postcard.settings.replyAddress = action.payload
            })
    }
})

export const {
    updateImage,
    updateLayout,
    updateMessage,
    updateFont,
    setFontIfNotPresent,
    updateLocation,
    updateAddress,
    updateReplyBackSetting,
    updateChainSetting,
    updateChainName,
    updateReplyAddress,
    updateEnvelope,
    processInitialState,
    processGoToStepRequest,
    processNextStepRequest,
    processPreviousStepRequest,
    clearState,
    closeErrorDialog,
    addErrorAlert,
    addInfoAlert,
    removeAlert
} = appSlice.actions
export { addPostcardToCart, fetchUserAddress }
export default appSlice.reducer
export const selectApp = (state: RootState) => state.app.postcard