import PageWrapper from "../components/PageWrapper";
import {Navigate, useLoaderData} from "react-router-dom";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import {useLocationState} from "../utils/useLocationState";
import {useEffect, useState} from "react";
import {Hub} from "aws-amplify/utils";
import CustomAuthenticator from "../components/CustomAuthenticator";
import {Box} from "@mui/material";
// @ts-ignore
import { Helmet } from 'react-helmet'
import {useAppSelector} from "../utils/store";
import {selectAuth} from "../components/auth/authSlice";
import {REDIRECT_URL_KEY} from "./RedirectionCallback";

type AuthStateParams = {
    redirectsTo?: string,
    paramsToRemember: undefined
} | {
    redirectsTo: string,
    paramsToRemember: string
}

const AuthenticationPage = () => {
    const state = useLocationState<AuthStateParams>()

    const {
        isLoggedIn
    } = useAppSelector(selectAuth)

    const redirectsTo = state?.redirectsTo
    let paramsToRemember: string | undefined = undefined
    if (redirectsTo)
        paramsToRemember = state?.paramsToRemember

    const getRedirectURL = (redirects: string): string => {
        if (paramsToRemember) {
            return redirects + '?' + paramsToRemember
        } else return redirects
    }

    if (redirectsTo)
        // Load value in cache in case we'll use social sign in
        localStorage.setItem(REDIRECT_URL_KEY, getRedirectURL(redirectsTo))

    return <PageWrapper>
        <Helmet>
            <meta name="title" content="Login - Memento Card | Accesso - Memento Card" />
            <meta name="description" content="Log in to your Memento Card account to
manage and personalize your postcard. Enter your credentials to access your
personal space. | Accedi al tuo account Memento Card per gestire e
personalizzare la tua cartolina. Inserisci le tue credenziali per entrare nel tuo
spazio personale." />
            <meta name="keywords" content="Login Memento Card, account access,
manage postcards, personalize postcards, Accesso Memento Card, login,
account Memento Card, gestione cartoline, personalizzazione cartoline" />
        </Helmet>
        <Box sx={{ pt: '40px', px: 2 }}>
            { isLoggedIn ? <Navigate to={redirectsTo ? getRedirectURL(redirectsTo) : '/'} /> : <CustomAuthenticator /> }
        </Box>
    </PageWrapper>
}

export default AuthenticationPage