import {EditAddressProps} from "./address";
import {useContext, useState} from "react";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import EditAddressBox from "./EditAddressBox";
import AddressTextField from "../../../../AddressTextField";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme} from "@mui/material";

const EditAddressComponentSmall = ({ address, setAddress }: EditAddressProps) => {
    const [editAddress, setEditAddress] = useState<boolean>(false)
    const onClickEditAddressBox = () => setEditAddress(true)

    const theme = useTheme();

    const postcardWidth = useContext(PostcardWidthContext).width

    const isEmptyAddress = (): boolean => !address.streetAddress && !address.fullName && !address.city && !address.zipCode

    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    return <>
        {isEmptyAddress() ? <EditAddressBox
            onClick={onClickEditAddressBox}
            postcardWidth={postcardWidth}
        /> : <Typography
            sx={{ fontFamily: 'Poste', fontSize: postcardWidth * 0.0176, textTransform: 'uppercase' }}
            onClick={onClickEditAddressBox}
        >
            {address.fullName} <br />
            {address.streetAddress} <br />
            {address.city} <br />
            {address.zipCode} <br />
        </Typography>}
        <Dialog
            open={editAddress}
            fullScreen
        >
            <DialogTitle>
                <Typography variant={'h6'}>Insert the destination address</Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{width: '100%', pt: 1, display: 'flex', flexDirection: 'column'}}>
                    <AddressTextField placeholder={'FULL NAME'} value={address.fullName}
                                      onChange={e => setAddress({ ...address, fullName: e.target.value })} small={true} />
                    <AddressTextField placeholder={'ADDRESS'} value={address.streetAddress}
                                      onChange={e => setAddress({ ...address, streetAddress: e.target.value })} small={true} />
                    <AddressTextField placeholder={'CITY, COUNTRY'} value={address.city}
                                      onChange={e => setAddress({ ...address, city: e.target.value })} small={true} />
                    <AddressTextField placeholder={'ZIP CODE'} value={address.zipCode}
                                      onChange={e => setAddress({ ...address, zipCode: e.target.value })} small={true} />
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 3, position: 'fixed', bottom: 0, right: 0, width: '100%' }}>
                <Button variant={"contained"} onClick={() => setEditAddress(false)} sx={{ width: '50%', ...buttonDefaults }}> Save </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default EditAddressComponentSmall