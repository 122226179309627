import PageWrapper from "../components/PageWrapper";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Await, Navigate, useLoaderData, useLocation, useNavigate} from "react-router-dom";
import React, {Suspense} from "react";
import {useAppDispatch, useAppSelector} from "../utils/store";
import {selectAuth} from "../components/auth/authSlice";
import CartPostcardRecap from "../components/postcardrecap/cartpostcardrecap/CartPostcardRecap";
import Checkout from "../components/app/common/Checkout";
import {getTotalPrice} from "../utils/utils";
import IsGreetingsContext from "../components/app/common/imagesselection/isGreetingsContext";
import AppSnackbar from "../components/app/common/AppSnackbar";
import {
    closeOverwriteDialog,
    deletePostcard,
    removeAlert, safeAddAnotherPostcard,
    selectCart,
    submitOrder
} from "../components/cart/cartSlice";
import { Cart as CartType } from '../api/api'

const Cart = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const data = useLoaderData() as { cart: CartType }

    const {
        isLoggedIn
    } = useAppSelector(selectAuth)

    const {
        alerts, cart, overwriteDialogOpen, isLoadingAddAnotherPostcard
    } = useAppSelector(selectCart)

    const dispatch = useAppDispatch()

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <PageWrapper>
        <IsGreetingsContext.Provider value={{ isGreetings: false }}>
        {isLoggedIn ?
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2, mt: 4 }}>
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 0.5, mb: 2 }}>
                        <Typography variant={'h6'}>Shopping cart</Typography>
                        <Suspense fallback={<Box/>}>
                            <Await
                                resolve={data.cart}
                                errorElement={
                                    <Typography>Failed to fetch cart</Typography>
                                }
                                children={_ => <>
                                    <Typography variant={'body1'}>
                                        You have {cart?.postcards.length ?? '0'} postcard{cart?.postcards.length === 1 ? '' : 's'} in your cart
                                    </Typography>
                                </>
                                }
                            />
                        </Suspense>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'column' : 'row', alignItems: 'start', width: '100%' }}>
                        <Suspense fallback={
                            <Box sx={{ width: belowMedium ? '100%' : 800, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }}>
                                <CircularProgress
                                    sx={{'& svg': {transform: 'scale(3) !important'}, marginBottom: 16, marginTop: 16}}/>
                            </Box>
                        }>
                            <Await
                                resolve={data.cart}
                                errorElement={<Box />}
                                children={_ => <Box sx={{ width: belowMedium ? '100%' : 800, mr: belowMedium ? 0 : 4, flexShrink: 0 }}>
                                    {cart?.postcards.length === 0 && <Box sx={{ pb: 2 }}>
                                        <Button variant={'contained'} onClick={() => navigate('/app?param=value')}>Add a postcard</Button>
                                    </Box>}
                                    {cart?.postcards.map(item => <CartPostcardRecap
                                        postcard={item.postcard}
                                        key={item.postcard.id}
                                        price={item.price}
                                        onRemovePostcard={() => {
                                            dispatch(deletePostcard({
                                                id: item.postcard.id,
                                                cartItemId: item.cartItemId
                                            }))
                                        }}
                                    />)}
                                </Box>}
                            />
                        </Suspense>

                        <Suspense>
                            <Await
                                resolve={data.cart}
                                children={_ => cart?.postcards.length ? <Checkout
                                    totalPrice={getTotalPrice(cart.postcards.map(item => item.price))}
                                    quantity={cart.postcards.length}
                                    onSubmitOrder={() => dispatch(submitOrder(cart.postcards))}
                                    onAddAnotherPostcard={() => {
                                        dispatch(safeAddAnotherPostcard(false)).then(result => {
                                            const shouldRedirect = result.payload
                                            if (shouldRedirect) navigate('/app')
                                        })
                                    }}
                                /> : <></>}
                            />
                        </Suspense>
                    </Box>
                </Box>
                {/* TODO remove duplicate from Exceptions component */}
                <AppSnackbar alerts={alerts} removeAlert={id => dispatch(removeAlert(id))} />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoadingAddAnotherPostcard}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog open={overwriteDialogOpen} onClose={() => dispatch(closeOverwriteDialog())} sx={{ '& .MuiPaper-root': { padding: 2, borderRadius: 4 } }}>
                    <DialogTitle>
                        Before that:
                    </DialogTitle>
                    <DialogContent>
                        There is another postcard being created. Do you want to overwrite it?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            dispatch(closeOverwriteDialog())
                            navigate('/app')
                        }} variant={'contained'}>
                            Continue with current postcard
                        </Button>
                        <Button onClick={() => {
                            dispatch(closeOverwriteDialog())
                            dispatch(safeAddAnotherPostcard(true)).then(ignored => {
                                navigate('/app')
                            })
                        }}>
                            Overwrite postcard
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box> : <Navigate to={'/auth'} state={{ redirectsTo: location.pathname }} />}
        </IsGreetingsContext.Provider>
    </PageWrapper>
}

export default Cart