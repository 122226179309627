import {store} from "../../../../utils/store";
import {defer} from "react-router-dom";
import {getUserOnServerAndSetGlobalState} from "../../../../utils/utils";
import {Cart, getFullUserCart} from "../../../../api/api";
import {getPriceFromSettingAndInitialState} from "../postcardAppUtils";
import {setCart} from "../../../cart/cartSlice";
import {fetchFonts} from "./fontsFetcher";
import {setFontIfNotPresent} from "../../postcard-app/utils/postcardSlice";

const getUserCart = async (): Promise<Cart | false> => {
    const user = await getUserOnServerAndSetGlobalState(store)
    if (user) {
        // Fetch user cart
        const userId = user.userId
        const cartData = await getFullUserCart(userId)
        if (!cartData) {
            // This may happen if the user has never put anything in the cart yet and thus the cart exists
            store.dispatch(setCart({
                id: '',
                postcards: [],
                // greetings: []
            }))
            return {
                id: '',
                postcards: [],
                // greetings: []
            }
        } else {
            const result: Cart = {
                id: cartData.id,
                postcards: [],
                // greetings: []
            }

            result.postcards = cartData.postcards.map(postcardData => ({
                postcard: postcardData.postcard,
                cartItemId: postcardData.cartItemId,
                price: getPriceFromSettingAndInitialState(
                    postcardData.postcard.settings,
                    postcardData.postcard.settings.initialState
                )
            }))
            // result.greetings = cartData.greetings.map(greetingsData => ({
            //     postcard: greetingsData.postcard,
            //     greetingsCartItemId: greetingsData.greetingsCartItemId,
            //     price: getPrice(PostcardAppInitialState.DEFAULT)
            // }))

            store.dispatch(setCart(result))
            return result
        }
    } else {
        store.dispatch(setCart({
            id: '',
            postcards: [],
            // greetings: []
        }))
        return {
            id: '',
            postcards: [],
            // greetings: []
        }
    }
}

const fetchCart = () => {
    return defer({
        cart: getUserCart(),
        // Cart is only for app and not for greetings
        fonts: fetchFonts(setFontIfNotPresent)
    })
}

export default fetchCart