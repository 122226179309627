import {ThemeProvider as AmplifyThemeProvider} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

import {RouterProvider} from "react-router-dom";
import {CssBaseline, ThemeProvider as MuiThemeProvider} from "@mui/material";
import muiTheme from './utils/themes/muiTheme';
import amplifyTheme from "./utils/themes/amplifyTheme";
import router from "./utils/routes";
import AuthenticationWrapper from "./components/auth/AuthenticationWrapper";
import {configureAmplify} from "./utils/utils";
// import LogRocket from 'logrocket';

// LogRocket.init('mbdl1m/memento');
configureAmplify()

export default function App() {
    return (
        <AmplifyThemeProvider theme={amplifyTheme}>
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                <AuthenticationWrapper>
                    <RouterProvider router={router} />
                </AuthenticationWrapper>
            </MuiThemeProvider>
        </AmplifyThemeProvider>
    )
}
