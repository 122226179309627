import {AuthenticatorProps} from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import {Authenticator, View, Text, Heading} from "@aws-amplify/ui-react";
import { signUp, SignUpInput } from 'aws-amplify/auth';

const CustomAuthenticator = (props: AuthenticatorProps) => {
    const currentYear = (new Date()).getFullYear()

    // TODO sort out how to have the same behavior on confirm after SIGN IN (if the user if very dumb)
    async function handleSignUp(input: SignUpInput) {
        const output = await signUp(input);
        if (output.nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
            console.log("CONFIRM_SIGN_UP: " + input.username)
            return {
                ...output,
                nextStep: {
                    ...output.nextStep,
                    codeDeliveryDetails: {
                        ...output.nextStep.codeDeliveryDetails,
                        // sign up email field name is generic `username`
                        destination: input.username,
                    },
                },
            };
        }
        return output;
    }

    const FooterComponent = <View textAlign="center" style={{ paddingBottom: 8, backgroundColor: 'white', paddingLeft: 16, paddingRight: 16 }}>
            <Text fontSize={12}>
                By registering you agree to the <a href={'/terms-and-conditions'}>Terms and Condition</a> and <a href={'/privacy-policy'}>Privacy Policy</a> of Memento S.r.l.
                &copy; {currentYear} Memento S.r.l. All Rights Reserved<br />
            </Text>
        </View>

    const ConfirmSignUpHeaderComponent = <View>
        <Heading level={3}>
            We emailed you
        </Heading>
        <Text fontSize={16} style={{ paddingTop: 16 }}>
            Please double check that the email address you put is correct.
        </Text>
    </View>

    return <Authenticator { ...props } socialProviders={[ 'google' ]} services={{ handleSignUp }} components={{
        SignUp: { Footer() { return FooterComponent } },
        ConfirmSignUp: { Header() { return ConfirmSignUpHeaderComponent } }
    }} />
}

export default CustomAuthenticator