import PageWrapper from "../components/PageWrapper";
import {Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Typography} from "@mui/material";
import React from "react";
// @ts-ignore
import { Helmet } from 'react-helmet'

const FAQ = () => {
    return <PageWrapper>
        <Helmet>
            <meta name="title" content="FAQ - Memento Card | FAQ - Memento Card" />
            <meta name="description" content="Find answers to frequently asked
questions about Memento Card. Learn how to create your postcard, delivery
times, customization options, and more. | Trova le risposte alle domande più
frequenti su Memento Card. Scopri come creare la tua cartolina, tempi di
consegna, opzioni di personalizzazione e molto altro." />
                <meta name="keywords" content="FAQ Memento Card, frequently asked
questions, Memento Card support, Memento Card help, create memory
postcards, postcard customization, travel postcards, Memento Card delivery
times, domande frequenti, supporto Memento Card, aiuto Memento Card,
creare cartoline ricordi, personalizzazione cartoline, cartoline viaggio, tempi
consegna Memento Card" />
        </Helmet>
        <Box sx={{ display: 'flex', width: 'calc(100% - 48px)', flexDirection: 'column', m: 3, my: 6,  alignItems: 'center' }}>
            <Typography variant={'h4'} sx={{ mb: 6 }}>
                Frequently Asked Question
            </Typography>
            <Card sx={{ width: '100%', p: { xs: 2, md: 4 }, borderRadius: 5, boxShadow: 'none' }} >
                <CardContent>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>How does Memento work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                With Memento you can send a real paper postcard to your loved ones all over the world.
                            </Typography>
                            <Typography variant={'body1'}>
                                <ul>
                                    <li>Upload a wonderful image</li>
                                    <li>Write a thoughtful message</li>
                                    <li>Insert the destination address</li>
                                    <li>We will print and ship your postcard to your loved one's home</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>What is the size of the postcard?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                The postcard is 10cm x 15cm. Made with love.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>How much does the postcard cost?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                A small price for a big gift.
                            </Typography>
                            <br/>
                            <Typography variant={'body1'}>
                                Italy:  3.59€<br/>
                                Europe: 3.99€<br/>
                                Rest of the world: 4.99€
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>How long does the postcard take to arrive?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                Your postcards will be delivered in around 1-2 weeks. Expect some delay if you ship a postcard to the North Pole, but do not worry, you will receive a notification once the QR will be scanned at destination!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>What is a Chain of Postcards?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                We love to connect people. You can create a chain of postcards around the world bonding together a group of friends, colleagues or a family.
                            </Typography>
                            <br/>
                            <Typography variant={'body1'}>
                                Send a postcard to Tom and Tom will send a new one to Alex. Everyone pays the postcard for the next one. Just scan the QR code when you receive a postcard to keep the chain going.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left' }}>What is Postcard + Reply Back?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                Let's be honest, you love to send postcards, but you also love to receive them! With reply back, in addition to your postcard, you pay in advance also the postcard for your friend’s reply. They will just have to scan the QR code from your postcard and send a new one for free back to you. Now your friends will not have excuses.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>Why should I insert my current location while preparing the postcard?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                Who receive the postcard loves to know from where the postcard comes from!
                            </Typography>
                            <br/>
                            <Typography variant={'body1'}>
                                Whether you’ll send your postcard from the peak of a mountain or from a beach during sunset, you will choose the place of origin of the postcard (GPS not required). By scanning the QR code, your loved ones will be able to see on a map the exact location from where you decided to share that special moment with them!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>Where is my postcard?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                Memento prints your postcard and sends it via postal service to the address you provide. Like a real postcard, there is no tracking available once it is sent—you just need to wait for the postal service to deliver it. Once it has been delivered and the recipient scans the QR code, you will receive an email! If you have any questions, feel free to contact us, and we’ll be happy to assist you!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>Who will see my personal address in the profile?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                In case you insert your personal address in the profile, the next time you send a postcard, the recipient will be able to save your contact to send you a postcard later in the future.
                            </Typography>
                            <br/>
                            <Typography variant={'body1'}>
                                Don’t worry, they can save your contact, but your address will always remain not visible to them
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: 'none', py: 2 }}>
                        <AccordionSummary expandIcon={<img alt={'Expand'} src={'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                            <Typography sx={{color: "#666666", fontSize: "24px", fontWeight: 500, pr: 2, textAlign: 'left'}}>What is the Memento Ambassador Program?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={'body1'}>
                                Memento stands for adventure, memory and sharing.
                            </Typography>
                            <br/>
                            <Typography variant={'body1'}>
                                Do you share these values as well? Visit our page and learn more about the <a href={'/ambassador'}>Ambassador Program</a>.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Box>
    </PageWrapper>
}

export default FAQ