import {Navigate, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import PageWrapper from "../components/PageWrapper";
import {Box} from "@mui/material";

export const REDIRECT_URL_KEY = 'redirectUrl'

const RedirectionCallback = () => {
    // const navigate = useNavigate();
    //
    // useEffect(() => {
    //     const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY) || '/'; // Default to home
    //     localStorage.removeItem(REDIRECT_URL_KEY); // Clean up
    //     navigate(redirectUrl, { replace: true }); // Redirect user to the original page
    // }, [navigate]);
    //
    // return <PageWrapper>
    //     <Box>
    //         Loading...
    //     </Box>
    // </PageWrapper>

    let redirectsTo = localStorage.getItem(REDIRECT_URL_KEY)
    if (!redirectsTo)
        redirectsTo = '/'

    return <Navigate to={redirectsTo} />
}

export default RedirectionCallback;